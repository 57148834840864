// @mui
import { IconButton } from '@mui/material';
// @mui Icons
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
// hooks
import useSettings from '../../hooks/useSettings';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function SettingMode() {
  const { themeMode, onChangeMode } = useSettings();

  return (
    <IconButton
      onClick={() => onChangeMode({
        target: {
          value: themeMode === 'dark' ? 'light' : 'dark'
        }
      })}
      sx={{ mr: 1, color: 'text.primary' }}
    >
      {themeMode === 'dark' ? <DarkModeIcon /> : <LightModeIcon />}
    </IconButton>
  );
}
