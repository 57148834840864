/* eslint-disable camelcase */
/* eslint-disable object-shorthand */
/* eslint-disable spaced-comment */
import { createSlice } from '@reduxjs/toolkit';
// QS
import queryString from 'query-string';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  devices: [],
  roomDevices: [],
  frigoDevices: [],
  device: null,
  deviceByRoom: null,
};

const slice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET DEVICES
    getDevicesSuccess(state, action) {
      state.isLoading = false;
      state.devices = action.payload;
    },

    // GET DEVICE
    getDeviceSuccess(state, action) {
      state.isLoading = false;
      state.device = action.payload;
    },

    // GET DEVICE BY ROOM
    getDeviceByRoomSuccess(state, action) {
      state.isLoading = false;
      state.deviceByRoom = action.payload;
    },

    // GET DEVICES BY ROOM
    getDevicesByRoomSuccess(state, action) {
      state.isLoading = false;
      state.roomDevices = action.payload;
    },

    // GET DEVICES BY FRIGO
    getDevicesByFrigoSuccess(state, action) {
      state.isLoading = false;
      state.frigoDevices = action.payload;
    },

    // CREATE DEVICE
    createDeviceSuccess(state, action) {
      const newDevice = action.payload;
      state.isLoading = false;
      state.devices = [...state.devices, newDevice];
    },

    // UPDATE DEVICE
    updateDeviceSuccess(state, action) {
      const device = action.payload;
      const updateDevice = state.devices.map((_device) => {
        if (_device.id === device.id) {
          return device;
        }
        return _device;
      });

      state.isLoading = false;
      state.devices = updateDevice;
    },

    // DELETE DEVICE
    deleteDeviceSuccess(state, action) {
      const { deviceId } = action.payload;
      const deleteDevice = state.devices.filter((device) => device.id !== deviceId);
      state.isLoading = false;

      state.devices = deleteDevice;
      state.roomDevices = deleteDevice;
      state.frigoDevices = deleteDevice;
    },

    // DEVICE STATUS
    createDeviceStatus(state, action) {
      const newDeviceStatus = action.payload;
      state.isLoading = false;
      state.deviceStatus = [...state.deviceStatus, newDeviceStatus];
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
const DEVICE_API = `api/devices`;

// ----------------------------------------------------------------------

export function getDevices(ownerId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${DEVICE_API}?ownerId.eq=${ownerId}`);
      dispatch(slice.actions.getDevicesSuccess(response.data.content)); // Store content only
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function getAllDevices() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${DEVICE_API}`);

      dispatch(slice.actions.getDevicesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------------------------------------------------------------

export function getDeviceByRoom(roomId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`api/rooms/${roomId}/devices`);

      dispatch(slice.actions.getDeviceByRoomSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------------------------------------------------------------

export function getRoomDevices(deviceIds) {
  const roomDevices = queryString.stringify({ 'id.in': deviceIds }, { arrayFormat: 'comma' });
  console.log("test"  ,deviceIds);
  if (roomDevices === '' || roomDevices === undefined) {
    return () => [];
  }

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${DEVICE_API}?${roomDevices}`);

      dispatch(slice.actions.getDevicesByRoomSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------------------------------------------------------------

export function getFrigoDevices(deviceIds) {
  const frigoDevices = queryString.stringify({ 'id.in': deviceIds }, { arrayFormat: 'comma' });

  if (frigoDevices === '' || frigoDevices === undefined) {
    return async () => null;
  }

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${DEVICE_API}?${frigoDevices}`);

      dispatch(slice.actions.getDevicesByFrigoSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDevice(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${DEVICE_API}/${id}`);

      dispatch(slice.actions.getDeviceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createDevice(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios(DEVICE_API, {
        method: 'post',
        data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      const res = await axios(`api/device-statuses`, {
        method: 'post',
        data: {
          deviceId: response.data.id,
          ownerId: response.data.ownerId,
          lastStream: null,
          firstStream: null,
          workDuration: 0,
          workTime: 0,
          offlineDate: '',
          status: response.data.status,
          notes: 'Notes',
          installationDate: new Date(),
          isDisturbed: false,
          disturbanceStream: {},
        },
      });

      dispatch(slice.actions.createDeviceSuccess(response.data));
      dispatch(slice.actions.createDeviceStatus(res.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateDevice(deviceId, data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios({
        method: 'put',
        url: `${DEVICE_API}/${deviceId}`,
        data,
      });
      dispatch(slice.actions.updateDeviceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function deleteDevice(deviceId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${DEVICE_API}/${deviceId}`);

      dispatch(slice.actions.deleteDeviceSuccess({ deviceId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------
