// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  
  const { owner } = useAuth();

  return (
    <Avatar
      src={owner?.logoUrl}
      alt={owner?.name}
      color={owner?.logoUrl ? 'default' : createAvatar(owner?.name).color}
      {...other}
    >
      {createAvatar(owner?.name).name}
    </Avatar>
  );
}
