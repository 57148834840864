import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  frigos: [],
  frigoById: null,
  frigoByName: null,
  sortBy: null,
};

const slice = createSlice({
  name: 'frigo',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // DELETE FRIGO
    deleteFrigoSuccess(state, action) {
      const { id } = action.payload;

      const deleteFrigo = state.frigos.filter((frigo) => frigo.id !== id);
      state.frigos = deleteFrigo;
    },

    // GET FRIGOS
    getFrigosSuccess(state, action) {
      state.isLoading = false;
      state.frigos = action.payload;
    },

    // GET FRIGO BY ID
    getFrigoByIdSuccess(state, action) {
      state.isLoading = false;
      state.frigoById = action.payload;
    },

    // GET FRIGO BY NAME
    getFrigoByNameSuccess(state, action) {
      state.isLoading = false;
      state.frigoByName = action.payload;
    },

    // ADD NEW FRIGO
    createFrigoSuccess(state, action) {
      const newFrigo = action.payload;
      state.isLoading = false;
      state.frigos = [...state.frigos, newFrigo];
    },

    // UPDATE FRIGO SUCCESS
    updateFrigoSuccess(state, action) {
      const frigo = action.payload;
      const updateFrigo = state.frigos.map((_frigo) => {
        if (_frigo.id === frigo.id) {
          return frigo;
        }
        return _frigo;
      });

      state.isLoading = false;
      state.frigos = updateFrigo;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  sortByFrigos,
} = slice.actions;

// ----------------------------------------------------------------------

export function getFrigos(ownerId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`api/frigos?ownerId.eq=${ownerId}`);
      dispatch(slice.actions.getFrigosSuccess(response.data.content));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFrigoById(frigoId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`api/frigos/${frigoId}`);
      dispatch(slice.actions.getFrigoByIdSuccess(response.data));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFrigoByName(name) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`api/frigos?name.eq=${name}`);
      dispatch(slice.actions.getFrigoByNameSuccess(response.data));

    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteFrigo(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`api/frigos/${id}`);

      dispatch(slice.actions.deleteFrigoSuccess({ id }));

    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -----------------------------------------------------------------------------

export function addFrigo(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {

      const response = await axios('api/frigos', {
        method: "post",
        data,
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        }
      });

      dispatch(slice.actions.createFrigoSuccess(response.data));

    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -----------------------------------------------------------------------------

export function updateFrigo(data, frigoId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {

      const response = await axios({
        method: 'patch',
        url: `frigos/${frigoId}`,
        data,
      });

      dispatch(slice.actions.updateFrigoSuccess(response.data));

    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}