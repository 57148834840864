// Icons
import KitchenIcon from '@mui/icons-material/Kitchen';
import SettingsIcon from '@mui/icons-material/Settings';
import EngineeringIcon from '@mui/icons-material/Engineering';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DevicesIcon from '@mui/icons-material/Devices';
import { PresentationChart, Airplay } from 'phosphor-react';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('carbon:report'),
  analytics: getIcon('ic_analytics'),
  dashboard: <SummarizeIcon />,
  frigos: <KitchenIcon />,
  settings: <SettingsIcon />,
  maintenance: <EngineeringIcon />,
  utilisateurs: <PeopleAltIcon />,
  device: <DevicesIcon />,
  statistics: <PresentationChart size={28} />,
  monitoring: <Airplay size={28} />,
};

const navConfig = [
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Surveillance',
    items: [
      // USER
      { title: 'Tableau de bord', path: PATH_DASHBOARD.frigo.dashboard, icon: ICONS.analytics },
      // FRIGO
      {
        title: 'Frigos',
        path: PATH_DASHBOARD.frigo.list,
        icon: ICONS.frigos,
        children: [
          { title: 'Zones', path: PATH_DASHBOARD.frigo.list },
          { title: 'Chambres', path: PATH_DASHBOARD.room.list },
          { title: 'Energie', path: PATH_DASHBOARD.energy.list },
        ],
      },
      // { title: 'Statistics ', path: '/dashboard/statistics', icon: ICONS.statistics },
      // { title: 'Appareils', path: '/dashboard/devices', icon: ICONS.device },
      // { title: 'Reporting', path: PATH_DASHBOARD.general.booking, icon: ICONS.dashboard },
      // { title: 'Maintenance', path: PATH_DASHBOARD.general.banking, icon: ICONS.maintenance },
      { title: 'Utilisateurs', path: PATH_DASHBOARD.general.users, icon: ICONS.utilisateurs },
      // { title: 'Paramètres', path: PATH_DASHBOARD.general.settings, icon: ICONS.settings },
      // { title: 'Monitoring', path: PATH_DASHBOARD.general.monitoring, icon: ICONS.monitoring },
    ],
  },
];

export default navConfig;
