import { capitalCase } from 'change-case';
import { useState, useEffect, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
// sections
import { LoginForm } from '../../sections/auth/login';
// SVG
import image1 from '../../imgs/pic1.svg';
import image2 from '../../imgs/undraw_cohort_analysis_stny.svg';
import image3 from '../../imgs/pic2.svg';
import login from '../../imgs/Login.svg';
// Images
import logo from '../../imgs/logo.png';

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    // justifyContent: 'center',
    // padding: theme.spacing(12),
  },
}));

const SectionStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  maxWidth: 700,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // margin: theme.spacing(2, 0, 2, 2),
  backgroundColor: '#548D05',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
  paddingInline: theme.spacing(5)
}));

// -----------------------------------------------

export default function Login() {
  const { method } = useAuth();

  const theme = useTheme();

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <img alt='logo' src={logo} width={138} style={{ position: 'absolute', top: 42, left: 42 }} />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingInline: 20, minHeight: '100vh', minWidth: '100%' }}>
        <Card sx={{ height: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '175vh' }} style={{ boxShadow: 'none', backgroundColor: '#161c24' }}>
          <Container maxWidth="sm">
            <ContentStyle>
              <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h4" gutterBottom>
                    Bienvenue sur la plateforme <span style={{ color: '#69A508' }}>Frigo</span>
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }}>Veuillez saisir vos identifiants.</Typography>
                </Box>
              </Stack>
              <LoginForm />
            </ContentStyle>
          </Container>
        </Card>
      </div>
    </Page>
  );
}
