/* eslint-disable camelcase */
/* eslint-disable object-shorthand */
/* eslint-disable spaced-comment */
import { createSlice } from '@reduxjs/toolkit';
import queryString from 'query-string';
import axios from '../../utils/axios';
import { dispatch } from '../store';

const initialState = {
  isLoading: false,
  error: null,
  streams: [],
  roomStreams: [],
  tableRoomStreams: [],
  energyStreams: [],
  allDeviceData: [],
};

const slice = createSlice({
  name: 'stream',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getStreamsSuccess(state, action) {
      state.isLoading = false;
      state.streams = action.payload;
    },
    getRoomStreamsSuccess(state, action) {
      state.isLoading = false;
      state.roomStreams = action.payload;
    },
    getTableRoomStreamsSuccess(state, action) {
      state.isLoading = false;
      state.tableRoomStreams = action.payload;
    },
    getEnergyStreamsSuccess(state, action) {
      state.isLoading = false;
      state.energyStreams = action.payload;
    },
    getStreamSuccess(state, action) {
      state.isLoading = false;
      state.stream = action.payload;
    },
    getStreamByDeviceSuccess(state, action) {
      state.isLoading = false;
      state.streamByDevice = action.payload;
    },
    getDeviceDataSuccess(state, action) {
      state.isLoading = false;
      state.allDeviceData = action.payload;
    },
  },
});

export default slice.reducer;

const API_STREAMS = `api/streams`;

export function getStreams() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(API_STREAMS);
      dispatch(slice.actions.getStreamsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRoomStreams(deviceIds, from, to) {
  return async (dispatch) => {
    if (!deviceIds || deviceIds.length === 0) {
      return;
    }
    dispatch(slice.actions.startLoading());
    try {
      const deviceQuery = deviceIds.map(id => `${id}`).join(',');
      const response = await axios.get(`api/streams/${deviceQuery}/between?startDate=${from}&endDate=${to}&page=0&size=5000`);
      const transformedData = response.data.content.map(stream => ({
        ...stream,
        params: stream.value
      }));
      dispatch(slice.actions.getRoomStreamsSuccess(transformedData));
    } catch (error) {
      dispatch(slice.actions.hasError({
        message: error.message,
        code: error.code
      }));
      console.error('Error fetching room streams:', error);
    }
  };
}

export function getTableRoomStreams(deviceIds, from, to) {
  return async (dispatch) => {
    if (!deviceIds || deviceIds.length === 0) {
      return;
    }
    dispatch(slice.actions.startLoading());
    try {
      const deviceQuery = deviceIds.map(id => `${id}`).join(',');
      const response = await axios.get(`api/streams/${deviceQuery}/between?startDate=${from}&endDate=${to}&page=0&size=5000`);
      const transformedData = response.data.content.map(stream => ({
        ...stream,
        params: stream.value
      }));
      dispatch(slice.actions.getTableRoomStreamsSuccess(transformedData));
    } catch (error) {
      dispatch(slice.actions.hasError({
        message: error.message,
        code: error.code
      }));
      console.error('Error fetching room streams:', error);
    }
  };
}

export function getEnergyStreams() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_STREAMS}?type.eq=ENERGY`);
      dispatch(slice.actions.getEnergyStreamsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStream(streamId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_STREAMS}/id/${streamId}`);
      dispatch(slice.actions.getStreamSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStreamByDevice(deviceId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_STREAMS}?deviceId.eq=${deviceId}`);
      dispatch(slice.actions.getStreamByDeviceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllDeviceData(from, to) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const deviceIds = [
      '67bcc118ceaec47c10bf29bd',
      '67bcc184ceaec47c10bf29be',
      '67bcc200ceaec47c10bf29bf',
      '67bcc2cdceaec47c10bf29c0'
    ];

    try {
      const responses = await Promise.all(deviceIds.map(deviceId =>
        axios.get(`${API_STREAMS}/${deviceId}/between?startDate=${from}&endDate=${to}&page=0&size=5000`)
      ));

      let allDeviceData = responses.flatMap(response =>
        response.data.content.map(stream => ({
          ...stream,
          temperature: Number(stream.value.temperature).toFixed(2),
          humidity: Number(stream.value.humidity).toFixed(2),
        }))
      );

      if (allDeviceData.length === 0) {
        const lastStreamsResponse = await axios.get(`${API_STREAMS}/last-streams?page=0&size=500`);
        
        allDeviceData = lastStreamsResponse.data.map(stream => ({
          ...stream,
          temperature: Number(stream.value.temperature).toFixed(2),
          humidity: Number(stream.value.humidity).toFixed(2),
        }));
      }

      dispatch(slice.actions.getDeviceDataSuccess(allDeviceData));
    } catch (error) {
      dispatch(slice.actions.hasError({
        message: error.message,
        code: error.code
      }));
      console.error('Error fetching device data:', error);
    }
  };
}
