import { createContext, useEffect, useReducer } from 'react';
// prop types
import PropTypes from 'prop-types';
// utils
import axios from '../utils/axios';
import { setSession } from '../utils/jwt';

const API_BASE_URL = 'https://api.frigo.ma'; // Ensure HTTP

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  owner: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, owner } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      owner
    };
  },
  LOGIN: (state, action) => {
    const { user, owner } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      isInitialized: true,
      user,
      owner
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    owner: null
  })
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  loginUser: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      const accessToken = window.localStorage.getItem('accessToken');

      try {
        if (accessToken) {
          setSession(accessToken);

          const response = await axios.get(`${API_BASE_URL}/api/account`);
          const response2 = await axios.get(`${API_BASE_URL}/api/account`);

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              isInitialized: true,
              user: response.data,
              owner: response2.data,
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
              owner: null
            }
          });
        }
      } catch (err) {
        if (accessToken) {
          setSession(null);
        }
        console.log(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            owner: null
          }
        });
      }
    };

    initialize();
  }, []);

  const loginUser = async ({ username, password, rememberMe }) => {
    const response = await axios.post(`${API_BASE_URL}/api/authenticate`, {
      username,
      password
    });

    const token = response.data.id_token;
    setSession(token);

    const userData = await axios.get(`${API_BASE_URL}/api/account`);
    const ownerData = await axios.get(`${API_BASE_URL}/api/account`);

    dispatch({
      type: 'LOGIN',
      payload: { 
        user: userData.data, 
        owner: ownerData.data, 
      }
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        loginUser,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node
};

export { AuthContext, AuthProvider };